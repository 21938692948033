header {
    margin-bottom: 100px;
}

header .navbar {
    padding: 7.5px 0;
}

header .navbar .navbar-brand img {
    width: 305px;
    height: 75px;
}

header .navbar .navbar-toggler {
    place-items: center;
    background: var(--hpt-primary);
    border-radius: 15px;
    border-color: var(--hpt-primary);
    color: rgba(0, 0, 0, 0.55);
    width: 51px;
    height: 51px;
    padding: 0;
}

header .navbar .navbar-toggler .navbar-toggler-icon {
    background-image: none;
    display: grid;
    place-items: center;
}

header .navbar .navbar-toggler .navbar-toggler-icon img {
    width: 30px;
    height: 18px;
}

header .navbar .navbar-nav .nav-item {
    display: grid;
    place-items: center;
}

header .navbar .navbar-nav .nav-link {
    font-weight: 500;
    font-size: 18px;
    color: var(--hpt-dark);
    padding: 8px 20px;
}

header .navbar .navbar-nav .show>.nav-link,
header .navbar .navbar-nav .nav-link.active {
    font-weight: 700;
}

header .navbar .btn-primary {
    background-color: var(--hpt-secondary);
    border-color: var(--hpt-secondary);
    border-radius: 10px;
    display: grid;
    place-items: center;
    width: 186px;
    height: 66px;
}

/* `xl` applies to large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    header {
        margin-bottom: 85px;
    }

    header .navbar .navbar-toggler {
        display: grid;
    }

    header .navbar .offcanvas-body {
        display: grid;
        justify-content: center;
    }

    header .navbar .navbar-brand img {
        width: 250px;
        height: auto;
    }
}

/* `sm` applies to x-small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    header {
        margin-bottom: 75px;
    }

    header .navbar .navbar-brand img {
        width: 200px;
        height: auto;
    }
}