:root {
  /* Huddersfield Prayer Times All Colors */
  --hpt-primary: #3FB79A;
  --hpt-secondary: #3FB79A;
  --hpt-dark: #2D2D2D;
  --hpt-white: #FFFFFF;
  --hpt-gray: #E9E9E9;
  --hpt-black: #000000;
}

a:focus,
a:focus-visible,
button:focus,
a:focus-visible {
  box-shadow: none !important;
  outline: none !important;
}

body {
  font-family: 'Red Hat Display', sans-serif;
}

.btn-group {
  margin-top: 10px;
}

.btn-group .store-button {
  width: 166.26px;
  height: 50px;
}

.btn-group .store-button+.store-button {
  margin-left: 20px;
}

.home .section-2-3-4 {
  margin-top: 30px;
}

/* All Sections Card Style */
.hpt-content-card {
  background: #FAFAFA;
  border-radius: 15px;
}

.hpt-content-card .card-header {
  background: var(--hpt-primary);
  border: 1px solid;
  border-color: var(--hpt-primary);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: var(--hpt-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  padding: 10px 24px;
}

.hpt-content-card .card-header .title {
  margin-bottom: 0;
}

.hpt-content-card .card-header .title,
.hpt-content-card .card-header .today-date {
  font-size: 24px;
}

.hpt-content-card .card-header+.card-body {
  padding: 30px 24px;
  border: 1px solid var(--hpt-primary);
  border-top: none;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}


.monthField input[type="month"] {
  position: relative !important;
}

.monthField input[type="month"]:after {
  content: "\f133" !important;
  color: #555 !important;
  font-family: FontAwesome !important;
  padding: 0px !important;
}


.monthField input[type="month"]:hover:after {
  color: #555 !important;
  transition: 0.3s ease-in-out !important;
}

.monthField input[type="month"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}


.monthField input[type="month"]::-webkit-inner-spin-button {
  z-index: 1;
}


.monthField input[type="month"]::-webkit-clear-button {
  z-index: 1;
}

.jcalendar-input {
  height: 37px !important;
  padding: 0px 7px !important;
  margin-top: 7px !important;
  border-radius: 3px !important;
  border: 1px solid black !important;
  cursor: pointer !important;
}

.jcalendar-input::placeholder {

  color: black !important;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

  .monthField input[type="month"] {
    width: 30%;
    padding: 6px 8px;
    border-radius: 2px;
    border: 1px solid black;
  }

}

/* `xl` applies to large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

  .home .section-2-3-4,
  .home .section-2-3-4>[class*="container"]>.row>[class*="col-"]+[class*="col-"] {
    margin-top: 50px;
  }

  .hpt-content-card .card-header {
    align-items: flex-start;
    flex-direction: column;
  }

}

/* `md` applies to small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {

  .btn-group .store-button+.store-button {
    margin: 0 0 0 10px;
  }

  .monthField input[type="month"] {
    width: 100% !important;
    padding: 6px 8px;
    border-radius: 2px;
    border: 1px solid black;
  }

  .monthField input[type="month"]:after {
    content: "\f133" !important;
    color: #555 !important;
    font-family: FontAwesome !important;
    padding: 0px !important;
    right: 4% !important;
    position: absolute !important;
  }

}

@media (max-width: 360px) {
  .btn-group {
    display: flex;
    flex-direction: column;
    place-items: center;
  }

  .btn-group .store-button+.store-button {
    margin: 10px 0 0 0;
  }
}