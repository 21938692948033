footer {
    background: #FAFAFA;
    padding: 45px 0;
    margin-top: 50px;
}

footer .container .row .app-logo img {
    width: 300px;
}

footer .container .row .btn-group,
footer .container .row .copyright-text {
    margin-top: 30px;
}

footer .container .row .copyright-text p {
    color: var(--hpt-dark);
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 0;
}

/* `xl` applies to large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    footer {
        margin-top: 0;
    }
}

/* `sm` applies to x-small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    footer .container .row .app-logo img {
        width: 280px;
    }
}

@media (max-width: 360px) {
    footer .container .row .app-logo img {
        width: 200px;
    }
}