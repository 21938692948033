.section-5 {
    margin-top: 30px;
}

.section-5 .hpt-content-card .card-header {
    background: var(--hpt-secondary);
    border-color: var(--hpt-secondary);
}

.section-5 .hpt-content-card .card-header .today-date {
    text-align: right;
    display: flex;
    flex-wrap: wrap;
}

.section-5 .hpt-content-card .card-header .title,
.section-5 .hpt-content-card .card-header .today-date,
.section-5 .hpt-content-card .card-header .today-date .jcalendar-input {
    font-size: 30px;
}

.section-5 .hpt-content-card .card-header .today-date .jcalendar-input {
    background: transparent;
    color: var(--hpt-white) !important;
    border: none !important;
    font-weight: 700;
    height: auto !important;
    margin-top: 0 !important;
    text-align: right;
    caret-color: transparent;
    position: relative;
    z-index: 1;
    padding-right: 45px !important;
}

.section-5 .hpt-content-card .card-header .today-date::after {
    content: url("/public/calendar-icon.svg");
    position: absolute;
    top: 15px;
    right: 22px;
    z-index: 0;
}

.section-5 .hpt-content-card .card-header .today-date .jcalendar-input:focus-visible {
    outline: none;
}

.section-5 .hpt-content-card .card-header .today-date .jcalendar-input:visited {
    text-decoration: none;
}

.section-5 .hpt-content-card .card-header .today-date .jcalendar-input::-webkit-input-placeholder {
    color: var(--hpt-white) !important;
    font-weight: 700;
    text-align: right;
    opacity: 1;
}

.section-5 .hpt-content-card .card-header .today-date .jcalendar-input:-moz-placeholder {
    color: var(--hpt-white) !important;
    font-weight: 700;
    text-align: right;
    opacity: 1;
}

.section-5 .hpt-content-card .card-header .today-date .jcalendar-input::-moz-placeholder {
    color: var(--hpt-white) !important;
    font-weight: 700;
    text-align: right;
    opacity: 1;
}

.section-5 .hpt-content-card .card-header .today-date .jcalendar-input:-ms-input-placeholder {
    color: var(--hpt-white) !important;
    font-weight: 700;
    text-align: right;
    opacity: 1;
}

.section-5 .hpt-content-card .card-header .today-date .jcalendar-input::-ms-input-placeholder {
    color: var(--hpt-white) !important;
    font-weight: 700;
    text-align: right;
    opacity: 1;
}

.section-5 .hpt-content-card .card-header .today-date .jcalendar-input::placeholder {
    color: var(--hpt-white) !important;
    font-weight: 700;
    text-align: right;
    opacity: 1;
}

.section-5 .hpt-content-card .card-header .today-date .jcalendar {
    right: -53px;
    top: 65px;
}

.section-5 .hpt-content-card .card-header .today-date .jcalendar .jcalendar-content .jcalendar-controls {
    font-size: 18px;

}

.section-5 .hpt-content-card .card-header .today-date .jcalendar .jcalendar-content .jcalendar-controls .jcalendar-reset,
.section-5 .hpt-content-card .card-header .today-date .jcalendar .jcalendar-content .jcalendar-controls .jcalendar-confirm {
    color: var(--hpt-primary);
}

.section-5 .hpt-content-card .card-header .today-date .jcalendar .jcalendar-content .jcalendar-controls .jcalendar-update {
    border-color: var(--hpt-secondary);
    background-color: var(--hpt-secondary);
    color: var(--hpt-white);
    border-radius: 4px;
}

.section-5 .hpt-content-card .card-header .today-date .jcalendar .jcalendar-table>table {
    background-color: var(--hpt-primary);
    font-size: 18px;
}

.section-5 .hpt-content-card .card-header .today-date .jcalendar .jcalendar-table .jcalendar-selected {
    background-color: var(--hpt-secondary);
}

.section-5 .hpt-content-card .card-body .table .table-dark {
    --bs-table-bg: var(--hpt-primary);
    color: #fff;
    border-color: #fff;
}

.section-5 .hpt-content-card .card-body .table.table-secondary {
    --bs-table-bg: #FFF7EE;
    --bs-table-striped-bg: var(--hpt-white);
}

.section-5 .hpt-content-card .card-body .table thead tr th {
    font-weight: 700;
    font-size: 18px;
}

.section-5 .hpt-content-card .card-body .table.table-secondary tbody,
.section-5 .hpt-content-card .card-body .table.table-secondary tbody tr,
.section-5 .hpt-content-card .card-body .table.table-secondary tbody td {
    border-color: #CECECE;
}

.section-5 .hpt-content-card .card-body .table.table-bordered tbody tr {
    border-width: 0;
}

.section-5 .hpt-content-card .card-body .table.table-bordered tbody tr:last-child {
    border-bottom-width: 1px;
}

.section-5 .hpt-content-card .card-body .table tbody tr .activeDay {
    background-color: var(--hpt-secondary) !important;
    --bs-table-accent-bg: var(--hpt-secondary);
    color: var(--hpt-white);
}

.section-5 .hpt-content-card .card-body .table tbody tr td {
    color: var(--hpt-dark);
    font-weight: 500;
    font-size: 15px;
    padding: 20.5px 10px;
    vertical-align: middle;
}

@media (max-width: 1199px) {
    .section-5 {
        margin-top: 50px;
    }

    .section-5 .hpt-content-card .card-header .today-date {
        text-align: left;
    }

    .section-5 .hpt-content-card .card-header .today-date .jcalendar-input {
        text-align: left;
        padding: 0 !important;
        width: 245px;
    }

    .section-5 .hpt-content-card .card-header .today-date .jcalendar-input::placeholder,
    .section-5 .hpt-content-card .card-header .today-date .jcalendar-input::-moz-placeholder,
    .section-5 .hpt-content-card .card-header .today-date .jcalendar-input::-webkit-input-placeholder {
        text-align: left;
    }

    .section-5 .hpt-content-card .card-header .today-date::after {
        position: relative;
        top: 4px;
        right: auto;
    }

    .section-5 .hpt-content-card .card-header .today-date .jcalendar {
        right: auto;
    }

    .section-5 .hpt-content-card .card-header+.card-body {
        padding: 0;
        border-radius: 0;
    }

    .section-5 .hpt-content-card .card-body .table.table-secondary {
        margin-bottom: 0;
    }

    .section-5 .hpt-content-card .card-body .table.table-bordered tbody tr:last-child {
        border-bottom-width: 0;
    }

    .section-5 .hpt-content-card .card-body .table.table-bordered tbody tr:last-child td {
        border-bottom-width: 0;
    }

    .section-5 .hpt-content-card .card-body .table thead tr th:first-child {
        padding: 8px 35px;
    }

}