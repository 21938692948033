.section-4 {
    margin-top: 24px;
}

.section-4 .hpt-content-card .card-header+.card-body>.row>[class*="col-"] {
    margin-top: 0px;
}

.section-4 .hpt-content-card .card-body .row .mapouter {
    border: 1px solid #E3E3E3;
    border-radius: 10px;
    position: relative;
    text-align: right;
    height: 392px;
    width: 100%;
}

.section-4 .hpt-content-card .card-body .row .mapouter .gmap_canvas {
    overflow: hidden;
    background: none;
    height: 392px;
    width: 100%;
}

.section-4 .hpt-content-card .card-body .row .address-details {
    color: var(--hpt-dark);
}

.section-4 .hpt-content-card .card-body .row .address-details .address-title {
    font-size: 18px;
}

.section-4 .hpt-content-card .card-body .row .address-details p {
    font-weight: 500;
    font-size: 18px;
    line-height: 2;
    margin-bottom: 0;
}

@media (max-width: 1199px) {
    .section-4 {
        margin-top: 50px;
    }

    .section-4 .hpt-content-card .card-body .row [class*="col-"]+[class*="col-"] {
        margin-top: 30px;
    }
}