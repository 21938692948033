.section-6 {
    margin-top: 50px;
}

.section-6 .hero-wrapper {
    background: rgba(212, 150, 82, 0.15);
    border-radius: 30px;
    padding: 30px 40px 0px;
}

.section-6 .hero-wrapper .row [class*="col-"]:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section-6 .hero-wrapper .row [class*="col-"] h1 {
    color: var(--hpt-dark);
    font-weight: 700;
    font-size: 55px;
    margin-bottom: 0;
}

.section-6 .hero-wrapper .row [class*="col-"] .btn-group {
    margin-top: 25px;
}

.section-6 .hero-wrapper .row [class*="col-"]+[class*="col-"] img {
    width: 383px;
}

/* `xl` applies to large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    .section-6 {
        background: rgba(212, 150, 82, 0.15);
    }

    .section-6 .hero-wrapper {
        background: transparent;
    }

    .section-6 .hero-wrapper .row [class*="col-"] {
        text-align: center;
    }

    .section-6 .hero-wrapper .row [class*="col-"]+[class*="col-"] {
        margin-top: 40px;
    }

    .section-6 .hero-wrapper .row [class*="col-"] h1 {
        font-size: 34px;
    }

    .section-6 .hero-wrapper .row [class*="col-"] .btn-group {
        margin-top: 40px;
        justify-content: center;
    }

}

/* `sm` applies to x-small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .section-6 .hero-wrapper .row [class*="col-"] h1 {
        font-size: 25px;
    }

    .section-6 .hero-wrapper .row [class*="col-"]+[class*="col-"] img {
        width: 100%;
    }
}

@media (max-width: 390px) {
    .section-6 .hero-wrapper .row [class*="col-"] h1 {
        font-size: 22px;
    }
}