.centered-hero-banner {
    background: url("/public/home-banner.jpg") center center no-repeat;
    background-size: cover;
}

.centered-hero-banner [class*="container"] {
    padding-top: 50px;
    padding-bottom: 31px;
}

.centered-hero-banner .row .today-date {
    color: var(--hpt-dark);
    font-size: 50px;
    margin-bottom: 0;
}

.centered-hero-banner .row .time-now {
    display: flex;
    place-content: center;
    place-items: center;
    margin-top: 30px;
}

.centered-hero-banner .row .time-now .wrapper {
    display: flex;
}

.centered-hero-banner .row .time-now .heading {
    font-size: 50px;
    color: var(--hpt-dark);
}

.centered-hero-banner .row .time-now [class*="time"] {
    background: var(--hpt-primary);
    border-radius: 20px;
    display: grid;
    place-items: center;
    color: var(--hpt-white);
    font-size: 70px;
    height: 104px;
    width: 104px;
    margin-left: 20px;
}

.centered-hero-banner .row .time-now .time-colon span {
    position: relative;
    bottom: 5px;
}

.centered-hero-banner .row .your-location {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 20px;
    margin-top: 25px;
}

.centered-hero-banner .row .your-location .fw-bold {
    margin-right: 10px;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .centered-hero-banner .row .today-date {
        display: flex;
        justify-content: center;
    }

    .centered-hero-banner .row .today-date div {
        margin-right: 10px;
    }
}

/* `xl` applies to large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    .centered-hero-banner {
        background: #fcf5ef url("/public/home-banner-mobile.jpg") center bottom no-repeat;
    }

    .centered-hero-banner [class*="container"] {
        padding-bottom: 250px;
    }
}

/* `md` applies to small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {

    .centered-hero-banner .row .today-date,
    .centered-hero-banner .row .time-now .heading {
        font-size: 35px;
    }

    .centered-hero-banner .row .time-now {
        flex-direction: column;
    }

    .centered-hero-banner .row .time-now .heading {
        font-size: 40px;
    }

    .centered-hero-banner .row .time-now .time-hour {
        margin-left: 0;
    }

}

/* `sm` applies to x-small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {}

@media (max-width: 360px) {
    .centered-hero-banner .row .time-now [class*="time"] {
        font-size: 30px;
        height: 52px;
        width: 52px;
    }

    .centered-hero-banner .row .time-now .time-colon span {
        bottom: 2px;
    }

}