.hpt-list-card {
    border-color: var(--hpt-primary);
    border-radius: 10px;
}

.hpt-list-card .list-group:first-child,
.hpt-list-card .list-group:last-child {
    border-radius: 10px;
}

.hpt-list-card .list-group .list-group-item {
    border-color: var(--hpt-primary);
}

.hpt-list-card .list-group .list-group-item {
    color: var(--hpt-black);
    display: flex;
    place-items: center;
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    padding: 14px;
}

.hpt-list-card .list-group .list-group-item::before {
    content: url(/public/mosque-bullet-point.svg);
    margin-right: 20px;
    width: 35px;
    height: 35px;
    align-self: flex-start;
}

.hpt-list-card .list-group .list-group-item.active {
    background: rgba(212, 150, 82, 0.1);
}

/* `xl` applies to large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    .hpt-list-card .list-group .list-group-item {
        position: relative;
    }
}