.section-3 .hpt-content-card .card-header+.card-body {
    padding: 6px 24px 30px;
}

.section-3 .hpt-content-card .card-header+.card-body>.row>[class*="col-"] {
    margin-top: 24px;
}

.section-3 .hpt-content-card .card-body .card {
    background: var(--hpt-white);
    border: 1px solid #ECECEC;
    box-shadow: 0px 4px 10px rgba(222, 222, 222, 0.25);
    border-radius: 5px;
}

.section-3 .hpt-content-card .card-body .card .card-body {
    color: var(--hpt-dark);
    padding: 18px 12px;
}

.section-3 .hpt-content-card .card-body .card .card-body .card-title {
    font-size: 20px;
    margin-bottom: 0;
}

.section-3 .hpt-content-card .card-body .card .card-body .row {
    margin-top: 10px;
}

.section-3 .hpt-content-card .card-body .card .card-body .row .icon-col {
    text-align: center;
}

.section-3 .hpt-content-card .card-body .card .card-body .row .icon-col .icon {
    width: 24px;
    height: 24px;
}

.section-3 .hpt-content-card .card-body .card .card-body .row .title-col {
    display: flex;
    align-items: center;
}

.section-3 .hpt-content-card .card-body .card .card-body .row .title-col .title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
}

.section-3 .hpt-content-card .card-body .card .card-body .row .time-stamp-col {
    display: flex;
    justify-content: center;
}

.section-3 .hpt-content-card .card-body .card .card-body .row .time-stamp-col .time-stamp {
    background: var(--hpt-secondary);
    border-radius: 5px;
    color: var(--hpt-white);
    font-size: 18px;
    text-align: center;
    width: 60px;
    height: 28px;
}

@media (max-width: 1199px) {
    .section-3 .hpt-content-card .card-header .today-date {
        margin-top: 5px;
    }
}